@media only screen and (min-width: 320px) and (max-width: 599px) {
  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding-left: 20px !important;

    .userInfo{
      padding-bottom: 5px !important;

      h4 {
        margin: auto;
        width: 218px;

        margin-top: 10px;
        margin-bottom: 6px;
        text-align: center !important;
        //display: -webkit-box;
        //-webkit-line-clamp: 2;
        //-webkit-box-orient: vertical;
        word-break: break-word;
        //overflow: hidden;
      }
      p:first-of-type{
        white-space: normal
      }
      p{
        margin: auto;
        margin-bottom: 1px;
        text-align: center;
        text-overflow: ellipsis;
        width: 210px;
        overflow: hidden;
        white-space: nowrap;
        line-height: 18px;
        color: #4c4b4b;
      }

    }
    .Image {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      border-radius: 50px;
      overflow: hidden;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 670px) {
  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px !important;

    .userInfo{
      padding-bottom: 5px !important;

      h4 {
        width: 200px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 6px;
        text-align: center !important;
        word-break: break-word;
      }
      p:first-of-type{
        white-space: normal
      }
      p{
        margin: auto;
        margin-bottom: 1px;
        text-align: center;
        text-overflow: ellipsis;
        width: 150px;
        overflow: hidden;
        white-space: nowrap;
        line-height: 18px;
        color: #4c4b4b;
      }

    }
    .Image {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      border-radius: 50px;
      overflow: hidden;
    }
  }
}
@media only screen and (min-width: 671px) and (max-width: 1023px) {
  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 16px !important;

    .userInfo{
      padding-bottom: 15px !important;

      h4 {
        width: 200px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 6px;
        text-align: center !important;
        word-break: break-word;

      }
      p:first-of-type{
        white-space: normal
      }
      p{
        margin: auto;
        color: #4c4b4b;
        margin-bottom: 0px;
        text-align: center;
        text-overflow: ellipsis;
        width: 210px;
        overflow: hidden;
        white-space: nowrap;
        line-height: 18px;
      }

    }
    .Image {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      border-radius: 50px;
      overflow: hidden;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .user {
    display: flex;
    margin: 0;
    //margin-bottom: 35px !important;
    padding-left: 16px !important;

    .userInfo{
      margin-left: 10px;
      padding-bottom: 35px;
      h4 {
        //margin: auto;
        width: 177px;
        margin-top: 21px;
        margin-bottom: 10px;
        text-align: left !important;
        word-break: break-word;
      }
      p:first-of-type{
        white-space: normal
      }
      p{
        color: #4c4b4b;
        margin-bottom: -1px;
        text-align: left;
        text-overflow: ellipsis;
        width: 205px;
        overflow: hidden;
        white-space: nowrap;
      }

    }
    .Image {
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      border-radius: 50px;
      overflow: hidden;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .user {
    display: flex;
    padding-left: 15px !important;

    .userInfo{
      padding-bottom: 59px !important;

      margin-left: 10px;
      h4 {
        width: 222px;
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: left !important;
        word-break: break-word;
      }
      p{

        margin-bottom: -1px;
        text-align: left;
        text-overflow: ellipsis;
        width: 270px;
        overflow: hidden;
        white-space: nowrap;
        color: #4c4b4b;
      }
    }
    .Image {
      height: 70px;
      width: 70px;
      border-radius: 50px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.userPlaceholder {
  background: url("../../../../../assets/img/cover-icon-user.svg") no-repeat !important;
  background-size: cover;
}
.Image{
  background-color: rgb(238, 238, 238);
  img{
    width: 150%;
  }
}
